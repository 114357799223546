<template>
    <main class="success page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">

        <section v-if="transactionReceiptData" class="success__info">
            <h1 class="title title__h1 success__title">{{ $t("GIFTCARD_PAYMENT_RESULT.INVITATION_ACCEPTED") }}</h1>
            <div class="success__time">
                <span class="success__time-text weight-700">{{ $t("COMMON.DATE") }}: </span>
                <span class="success__time-value">{{ transactionReceiptData.transDateTime | formatDate }}</span>
            </div>
            <div class="success__place">
                <span class="success__place-text weight-700">{{ $t("COMMON.PLACE") }}: </span>
                <span class="success__place-value">{{ transactionReceiptData.user_address }}</span>
            </div>
            <div class="success__agent">
                <span class="success__agent-text weight-700">{{ $t("GIFTCARD_PAYMENT_RESULT.AGENT") }}: </span>
                <span class="success__agent-value">{{ transactionReceiptData.business_name }}</span>
            </div>
        </section>

        <section v-if="transactionReceiptData" class="success__bill">
            <div class="success__bill-head">
                <div class="success__bill-wrapper">
                    <span class="success__text">{{ $t("COMMON.INVOICE") }}</span>
                    <span class="success__bill-number">{{ ' ' + transactionReceiptData.transId + ' ' }}</span>
                </div>
                <button @click="printReceipt" class="button success__print">
                    {{ $t("REPORT_SALES.FOR_PRINTING") }}
                </button>
            </div>

            <div class="success__body">
                <div class="success__caption">
                    <span class="success__goods">{{ $t("GIFTCARD_PAYMENT_RESULT.PRODUCT") }}</span>
                    <span class="success__quantity">{{ $t("GIFTCARD_PAYMENT_RESULT.QUANTITY") }}</span>
                    <span class="success__price">{{ $t("COMMON.PRICE") }}</span>
                </div>

                <div class="success__item">
                    <div class="success__item-wrapper">
                        <p class="text success__item-text">
                            {{ ' ' + transactionReceiptData.product + ' ' }}
                        </p>
                    </div>
                    <span class="success__quantity-value">1</span>
                    <div class="success__price-wrapper">
                        <span class="success__price-value">{{ ' ' + transactionReceiptData.price + ' ' }}</span>
                        <span class="success__current">{{ $t("COMMON.NIS") }}</span>
                    </div>
                </div>

                <div class="success__total">
                    <div class="success__total-wrapper">
                        <span class="success__total-text">{{ $t("GIFTCARD_PAYMENT_RESULT.TOTAL") }}</span>
                        <div class="success__amount">
                            <span class="success__amount-value">{{ ' ' + priceWithoutVAT + ' ' }}</span>
                            <span class="success__current">{{ $t("COMMON.NIS") }}</span>
                        </div>
                    </div>

                    <div class="success__total-wrapper">
                        <span class="success__total-text">{{ $t("GIFTCARD_PAYMENT_RESULT.VAT") }}</span>
                        <div class="success__amount">
                            <span class="success__amount-value">{{ ' ' + priceVAT + ' ' }}</span>
                            <span class="success__current">{{ $t("COMMON.NIS") }}</span>
                        </div>
                    </div>

                    <div class="success__total-wrapper success__total-wrapper_margin">
                        <span class="success__total-text">{{ $t("GIFTCARD_PAYMENT_RESULT.TOTAL_PAYMENT") }}</span>
                        <div class="success__amount success__amount_font_bold">
                            <span class="success__amount-value">{{ ' ' + price + ' ' }}</span>
                            <span class="success__current">{{ $t("COMMON.NIS") }}</span>
                        </div>
                    </div>
                </div>
            </div>

        </section>

    </main>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import appConfig from '../../appConfig'
export default {
    name: 'CommunicationPaymentSuccess',
    mounted() {},
    computed: {
        ...mapState({
            transactions_receipts: state => state.payment.transactions_receipts,
            current_transaction_supplier_id: state => state.payment.current_transaction_supplier_id,
            current_transaction_product_id: state => state.payment.current_transaction_product_id,
            supplier_items: state => state.supplier.supplier_items,
            suppliers: state => state.supplier.suppliers,
            suppliers_products: state => state.supplier.suppliers_products,
            products: state => state.product.products
        }),
        transactionId() {
            return this.$route.params.id
        },
        transactionSupplierId() {
            return this.current_transaction_supplier_id ? this.current_transaction_supplier_id : null
        },
        transactionProductId() {
            return this.current_transaction_product_id ? this.current_transaction_product_id : null
        },
        transactionReceiptData() {
            let transactionReceiptData = null;
            if (this.transactions_receipts && this.transactionId in this.transactions_receipts) {
                transactionReceiptData = {...this.transactions_receipts[this.transactionId]}
            }

            return transactionReceiptData ? transactionReceiptData : null
        },
        transactionSupplierData() {
            let supplierDataFromSuppliersItemsData = null;
            if (this.supplier_items) {
                for (let supplier_type in this.supplier_items) {
                    for (let suppliers_type_item of this.supplier_items[supplier_type]) {
                        if (suppliers_type_item.id == this.transactionSupplierId) {
                            supplierDataFromSuppliersItemsData = {...suppliers_type_item};
                            break;
                        }
                    }
                }
            }

            let supplierDataFromSuppliersData = null;
            if (this.suppliers && this.transactionSupplierId in this.suppliers) {
                supplierDataFromSuppliersData = {...this.suppliers[this.transactionSupplierId]}
            }

            let supplierData = supplierDataFromSuppliersData ? supplierDataFromSuppliersData : supplierDataFromSuppliersItemsData
            return supplierData ? supplierData : null
        },
        transactionProductData() {
            let productDataFromSuppliersData = null;

            if (this.suppliers_products) {
                for (let supplier_id in this.suppliers_products) {
                    for (let supplier_product_type in this.suppliers_products[supplier_id]) {
                        for (let supplier_product of this.suppliers_products[supplier_id][supplier_product_type]) {
                            if (supplier_product.hasOwnProperty('id')
                                && supplier_product.id == this.transactionProductId) {
                                productDataFromSuppliersData = {...supplier_product}
                                break;
                            }
                        }
                    }
                }
            }

            let productDataFromProductsData = null;
            if (this.products && this.transactionProductId in this.products) {
                productDataFromProductsData = {...this.products[this.transactionProductId]}
            }

            let productData = productDataFromProductsData ? productDataFromProductsData : productDataFromSuppliersData
            return productData ? productData : null
        },
        price() {
            return this.transactionReceiptData.price ? this.formatNumber(this.transactionReceiptData.price) : ''
        },
        priceVAT() {
            return this.price ? this.formatNumber(this.price * 0.17) : 0
        },
        priceWithoutVAT() {
            return this.price && this.priceVAT ? this.formatNumber(this.price - this.priceVAT) : this.price
        },
    },
    created() {
        this.getTransactionReceipt({'transaction_id': this.transactionId})

        if (this.transactionProductId) {
            this.getProduct({'product_id': this.transactionProductId})
        }

        if (this.transactionSupplierId) {
            this.getSupplier({'supplier_id': this.transactionSupplierId})
        }
    },
    watch: {},
    methods: {
        ...mapActions('payment', {
            getTransactionReceipt: 'getTransactionReceipt'
        }),
        ...mapActions('product', {
            getProduct: 'getProduct'
        }),
        ...mapActions('supplier', {
            getSupplier: 'getSupplier'
        }),
        getApiURL() {
            return appConfig.apiUrl
        },
        printReceipt() {
            window.print();
        },
        formatNumber(number) {
            let numberFormatObject = new Intl.NumberFormat('en-US')
            return numberFormatObject.format(number)
        }
    },
    updated() {},
}
</script>

<style>
    @media print {
        header,
        aside,
        button.button.success__print {
            display: none !important;
        }
    }
</style>

<style lang="scss" scoped>
.success__print {
    padding-left: 45px;
}
</style>